import React from "react"
const AboutPage = () => (

    <main>
      <h1>About the Author</h1>
      <p>Welcome to my Gatsby site.</p>
    </main>

)

export default AboutPage
